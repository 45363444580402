import Cookies from "js-cookie";

import { useEffect, useState } from "react";
import { usePiano } from "@sciam/piano/react";
import { pianoActivePurchase } from "@sciam/piano";
import { emailCollectorCookie } from "./constants";
import { useEntitlements } from "~features/access";

/**
 * Ensure the value is a number
 * @param {any} val
 * @param {number} fallback ]
 * @returns {number}
 */
const forceNumber = (val) => Number(val) || 0;

function log(msg) {
  if (typeof window === "undefined") return;
  if (window.location.search.match(/debug=meter/)) {
    console.debug(msg);
  }
}

/**
 * @param {String} articleId
 * @return {Number} how many articles a logged out user has read
 */
export function useArticleMeter(articleId = "", isNotPaywalled, isPreview = false) {
  const { hasAccess } = useEntitlements();

  // Meter should only run when:
  // 1. We've confirmed the user does not have access
  //    (true means they're a subscriber or inst users, undefined means we're still loading)
  // 2. The content itself is not exempt, either manaually, or because its a podcast or video.
  // 3. There's not a current purchase happening _right now_

  const doNotMeter =
    hasAccess !== false || isNotPaywalled || isPreview || pianoActivePurchase.isActive();

  // Rely on the legacy meter count
  // We're going to sync these for 90 days to ensure
  // we don't reset anyone's meters.
  //
  // @Warning:
  // - pianoMeterCount will be null by default, and if the meter doesn't tick.
  //   This means it will stay null on articles the reader already saw.
  // - Our cookie is stickier than theirs because its 1st party http. Because
  //   of this, we will assume the biggest number is always correct.
  const { pianoMeterCount } = usePiano();

  const [articleMeterCount, setArticleMeterCount] = useState(undefined);

  // Handle email collector
  const [hasEmailSub, setHasEmailSub] = useState(null);
  useEffect(() => {
    const hasRegistered = Cookies.get("_emailCollectorRegistered") === "1";
    setHasEmailSub(hasRegistered);
  }, []);

  /**
   * Ping the endpoint with the article ID so it can
   * add them to a persistent HTTP cookie.
   *
   * @param {number} articleId
   */
  function asynchronouslyUpdateTheMeter(articleId) {
    const endpoint = `/meter/?id=${articleId}`;
    return fetch(endpoint);
  }

  /**
   * One at a time, record a placeholder record
   * for any articles that Piano counted and we have not.
   * @param {number} numMissedArticles
   */
  async function backfillMeter(numMissedArticles) {
    console.log(`Backfilling ${numMissedArticles} articles to meter`);
    for (let index = 1; index <= numMissedArticles; index++) {
      const lostArticleId = `tp-view-${index}`;
      await asynchronouslyUpdateTheMeter(lostArticleId);
    }
  }

  // get cookie arr length and set state to its value
  useEffect(() => {
    // No need to count
    if (doNotMeter) {
      log("[meter] page is not metered");
      return;
    }

    let cookieValue = { ids: [], expires: null };
    try {
      // get cookie value; mysteriously, express seems to append a `j:` to it, so get rid of it
      cookieValue = JSON.parse(Cookies.get("article_meter").split("j:")[1]);
    } catch {}

    let count = cookieValue.ids.length;
    let hasSeenArticle = cookieValue.ids.includes(articleId);
    if (!hasSeenArticle) {
      count++;
      asynchronouslyUpdateTheMeter(articleId);
    }

    // If the Piano meter is higher, persist that value
    if (pianoMeterCount > count) {
      const numMissedArticles = pianoMeterCount - count;
      log([
        "Meter backfilled from Piano",
        { originalCount: count, newCount: pianoMeterCount, articlesBackfilled: numMissedArticles },
      ]);
      count = pianoMeterCount;
      backfillMeter(numMissedArticles);
    }

    setArticleMeterCount(count);
    log(["[meter]", { count, cookieValue, pianoMeterCount }]);
  }, [pianoMeterCount, doNotMeter, hasAccess]);
  return { meterCount: articleMeterCount, doNotMeter, hasEmailSub };
}
